import React from "react"
import "./style.css"
import { useTranslation } from "../../utils"

const Joomla = () => {
  const { t } = useTranslation()
  return (
    <section className="joomla container">
      <div className="joomla-main">
        <h1>
          Introducing <span className="totem">WebTotem</span> to Joomla
          community
        </h1>
        <h2>Website security and monitoring Plugin for Joomla</h2>
        <div className="joomla-links">
          <a
            href="/joomla/packages/wtotem_j4_latest.zip"
            download
            className="joomla-link joomla-link-v4"
          >
            Download <span>for joomla 4</span>
          </a>
          <a
            href="/joomla/packages/wtotem_j3_latest.zip"
            download
            className="joomla-link joomla-link-v3"
          >
            Download <span>for joomla 3</span>
          </a>
        </div>
      </div>
    </section>
  )
}

export default Joomla
