import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Header from "../../sections/header"
import Joomla from "../../sections/joomla"

const JoomlaPage = () => {
  return (
    <Layout>
      <SEO
        title="WebTotem plugin for Joomla"
        description="Download WebTotem plugin for Joomla"
      />
      <Header />
      <Joomla />
    </Layout>
  )
}

export default JoomlaPage
